/*
Adapted from 
https://github.com/daneden/animate.css
*/
.animated {
  animation-duration: 3s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.fadeIn {
  // animation-delay: 0.5s;
  animation-duration: 0.5s;
  animation-name: fadeIn;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}

.popFadeDelay {
  animation-delay: 0.5s;
  animation-duration: 0.25s;
  animation-name: fadeIn;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Tada */

@keyframes tada3rd {
  from {
    transform: scale3d(1, 1, 1);
  }

  3.3%,
  6.6% {
    transform: scale3d(0.8, 0.8, 0.8) rotate3d(0, 0, 1, -3deg);
  }

  10%,
  16.6%,
  23.3%,
  30% {
    transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, 3deg);
  }

  13.3%,
  20%,
  26.6% {
    transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, -3deg);
  }

  33.3% {
    transform: scale3d(1, 1, 1);
  }
}

.tada3rd {
  animation-name: tada3rd;
}

/* Flash */
@keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

/* Blinking */
@keyframes blinking {
  from,
  10%,
  20%,
  30% {
    opacity: 0.66;
  }

  5%,
  15%,
  25% {
    opacity: 0;
  }
}

/* Flip */
/*@keyframes flip {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    animation-timing-function: ease-out;
  }

  40% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    animation-timing-function: ease-out;
  }

  50% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    animation-timing-function: ease-in;
  }

  80% {
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    animation-timing-function: ease-in;
  }

  to {
    transform: perspective(400px);
    animation-timing-function: ease-in;
  }
}*/

/* Flip In X */
/*@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}*/

/* Highlight */

/*.highlight {
          animation-duration: 0.2s;
          animation-name: flip;
}*/

.td-highlight {
  animation-duration: 0.2s;
  animation-iteration-count: 3;
  animation-name: flash;
}

.live {
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-name: flash;
}

.blinking,
.status-warning > span:before {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: blinking;
}

// Works with most text elements.
.skeleton-box {
  // display: inline-block;
  // height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #eee;
  color: #eee;

  > * {
    visibility: hidden;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.2) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation: rotating 2s linear infinite;
}
