// Check boxes
input[type="checkbox"].ios {
  position: relative;
  appearance: none;
  outline: none;
  width: 40px;
  height: 24px;
  background-color: #ffffff;
  border: 2px solid #d9dadc;
  border-radius: 50px;
  box-shadow: inset -16px 0 0 0 #d9dadc;
  transition-duration: 200ms;
  transition-property: box-shadow;
  margin-left: 0;

  &:checked {
    border-color: var(--primary);
    box-shadow: inset 16px 0 0 0 var(--primary);

    &:after {
      left: 16px;
      box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.05);
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);

    transition-duration: 200ms;
    transition-property: box-shadow, left;
  }
}

.form-signin {
  h3 {
    // display: inline-block;
    border-bottom: #d4d4d4 solid 1px;
    font-family: $font-heading;
    font-weight: 300;
    padding-bottom: 10px;
  }
  .help-block {
    padding-bottom: 3px;
  }
}

fieldset:disabled {
  cursor: not-allowed;

  > * {
    pointer-events: none;
  }
}

// Buttons
fieldset[disabled] .btn,
a,
.btn {
  &.disabled,
  &[disabled] {
    opacity: 0.4;
    filter: alpha(opacity=40);
    pointer-events: none;
  }
}

.single-unit {
  pointer-events: none;

  button.dropdown-toggle {
    color: #555555;
    background-color: #eeeeee;

    .caret {
      display: none;
    }
  }
}

// error-messages
error-list {
  display: block;
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s ease-in-out;
}

.has-error > error-list {
  max-height: 100vh;
}

fieldset > error-list {
  margin-bottom: 15px;
  margin-top: -15px;
}

/* Estos estilos de error deberían ser para todos los formularios */
.has-error .form-control {
  background-color: #fff6f6;
}

.form-control-feedback {
  display: none;
}

.has-feedback .form-control-feedback {
  display: block;
}

.form-group {
  > label {
    // color: $gray-light;
    text-transform: uppercase;
    font-size: 90%;
  }
  .form-group {
    margin-bottom: 0px;
  }
}

select {
  // Displays the default option with placeholder styles
  &.has-placeholder {
    color: $input-color-placeholder;
    option {
      color: $input-color;
    }
    &.ng-dirty {
      color: $input-color;
    }
  }

  // Emulates a select readonly
  &[readonly],
  &.readonly {
    background: #eeeeee;
    cursor: no-drop;
    pointer-events: none;

    option {
      display: none;
    }
  }
}

// input buttons
.form-group.has-button {
  position: relative;

  &:hover {
    .form-control-button {
      display: block;
    }
  }

  > input:placeholder-shown + .form-control-button {
    display: none !important;
  }

  > input:focus + .form-control-button {
    display: block;
  }

  .input-lg + .form-control-button {
    width: 46px;
    height: 46px;
    line-height: 46px;
  }

  .input-sm + .form-control-button {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .input-grid + .form-control-button {
    width: 29px;
    height: 29px;
    line-height: 29px;
  }

  .form-control-button {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    cursor: pointer;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}

/* Radios */
.btn-group.btn-group-agree {
  .btn-default:active,
  .btn-default.active {
    color: #fff;
    background-color: #12a27e;
    border-color: #0f8265;
  }
}
/* Steps */
@keyframes appears {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

form {
  counter-reset: step_number;

  .step {
    counter-increment: step_number;
    /*padding: 0 32px 24px;*/
    padding-left: 40px;
    position: static;

    + .step {
      display: none;
    }

    .step-indicator {
      position: absolute;
      top: 0px;
      left: -24px;

      width: 24px;
      height: 24px;

      display: inline-block;
      padding: 3px;
      color: #0f8a6b;
      border: 2px solid #0f8a6b;
      line-height: 1;
      // vertical-align: middle;
      white-space: nowrap;
      text-align: center;
      // background-color: #b3b3b3;
      border-radius: 50%;

      > span::before {
        content: counter(step_number);
      }

      > {
        i,
        span {
          font-size: 14px;
          font-weight: bold;
          animation: appears 0.5s ease-out;
        }

        i {
          display: none;
        }
      }
    }

    .line {
      position: absolute;
      left: -13px;
      top: 0px;
      height: 100%;
      width: 1px;
      border-left: 2px dotted #0f8a6b;
      display: none;
      animation: fadeIn 1s ease-out;
    }

    &.completed {
      + .step {
        display: block;
      }

      .line {
        display: block;
      }

      .step-indicator {
        background-color: #0f8a6b;
        color: #fff;

        > {
          i {
            display: inline-block;
          }

          span {
            display: none;
          }
        }
      }
    }
  }

  .step:last-of-type {
    .line {
      display: none !important;
    }
  }
}

// Range sliders
input[type="range"] {
  width: 100%;
  margin: 12px 0;
  background-color: transparent;

  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    background: #f2f4f5;
    border: 1px solid #cccccc;
    border-radius: 25px;
    width: 100%;
    height: 8px;
    cursor: pointer;
  }
  &::-webkit-slider-thumb {
    margin-top: -5px;
    width: 16px;
    height: 16px;
    background: var(--primary);
    border: 1px solid #15b98f;
    border-radius: 50px;
    cursor: pointer;
    -webkit-appearance: none;
  }
  &::-moz-range-track {
    background: #f2f4f5;
    border: 1px solid #cccccc;
    border-radius: 25px;
    width: 100%;
    height: 8px;
    cursor: pointer;
  }
  &::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 4px 0;
    color: transparent;
    width: 100%;
    height: 8px;
    cursor: pointer;
  }
  &::-ms-fill-lower {
    background: #e2e2e2;
    border: 1px solid #cccccc;
    border-radius: 50px;
  }
  &::-ms-fill-upper {
    background: #f2f4f5;
    border: 1px solid #cccccc;
    border-radius: 50px;
  }
  &::-ms-thumb {
    width: 16px;
    height: 16px;
    background: var(--primary);
    border: 1px solid #15b98f;
    border-radius: 50px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
  }
}

.input-group {
  // Safari hack for display: table
  min-width: 100%;
}

#viewmode-toggle {
  margin-right: 6px;
}
