@use "sass:math";

/* Flex container */
.ag-container {
  display: flex;

  .ag-col-secondary {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .ag-col-primary {
    flex-grow: 1;
    min-width: 0;
    // overflow: auto;
  }
}

@media (max-width: 768px) {
  .ag-col-expanded-only {
    .ag-col-primary:not(.ag-col-expanded),
    .ag-col-secondary:not(.ag-col-expanded) {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .ag-container {
    flex-direction: column;
  }
}

// Landing
.landing-bg {
  position: fixed;

  > .gradient-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-100%, -50%);
    border-radius: 50%;
    background: var(--primary-darken-2);
    background: linear-gradient(0deg, var(--primary-darken-2) 25%, var(--primary-darken-3) 75%);

    > img {
      height: 200vw;
    }
  }

  > .overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.54);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }

  > .dots {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 18%;
  }

  > .background-images {
    display: flex;
    justify-content: flex-end;
    height: 100vh;
    width: 100vw;

    > div {
      background-repeat: no-repeat;
      background-size: cover;
    }

    > .w40 {
      width: 40%;
    }

    > .w25 {
      width: 25%;
    }

    > .field {
      background-image: url("/assets/img/home/bg-field@4k.jpg");
      background-position: left center;
    }
    > .teamwork {
      background-image: url("/assets/img/home/bg-teamwork@4k.jpg");
      background-position: center center;
    }
    > .fruits {
      background-image: url("/assets/img/home/bg-fruits@4k.jpg");
      background-position: right center;
    }
    > .grains {
      background-image: url("/assets/img/home/bg-grains@4k.jpg");
      background-position: left center;
    }
  }
}

.text-container {
  position: absolute;
  top: 0;
  width: 100%;

  .header,
  footer {
    padding: 30px 60px;
    color: #fff;
    font-family: $font-heading;
    letter-spacing: 0.5px;
    font-weight: 300;

    .logo {
      height: 45px;
    }
  }

  a.white {
    color: #fff;
  }

  .welcome-message {
    color: #fff;
    font-family: $font-heading;
    // letter-spacing: 0.5px;
    font-weight: 300;
    font-size: 24px;
    padding: 70px 140px;
    min-height: calc(100vh - 230px);

    > * {
      margin-bottom: 36px;
    }

    .main {
      font-weight: bold;
      font-size: 34px;
      // letter-spacing: 0;
    }

    .secondary {
      font-family: $font-basic;
      margin-bottom: 50px;
    }

    .tagline {
      font-weight: bold;
    }

    .icons {
      font-size: 12px;
      font-weight: bold;
      display: flex;
      justify-content: center;

      > div {
        margin: 0 38px;

        > img {
          height: 32px;
          margin: 8px;
        }
      }
    }
  }

  .divider-break {
    span {
      background-color: #fff;
    }
  }
}

@media (max-width: 991px) {
  .landing-bg {
    > .gradient-circle {
      display: none;
    }

    > .dots {
      width: 50%;
    }

    > .background-images {
      > .w40 {
        width: 50%;
      }
      > .w25 {
        width: 50%;
      }
    }
  }

  .text-container {
    .header,
    footer {
      padding: 20px;

      .logo {
        height: 30px;
      }
    }

    .header {
      background: var(--primary-darken-3);
    }

    .curve {
      padding: 0 0 20px;

      path {
        fill: var(--primary-darken-2);
      }
    }

    .welcome-message {
      font-size: 18px;
      padding: 20px;
      background: var(--primary-darken-2);
      background: linear-gradient(0deg, var(--primary-darken-2) 25%, var(--primary-darken-3) 75%);
      min-height: 0;

      > * {
        margin-bottom: 26px;
      }

      .main {
        font-size: 24px;
      }

      .secondary {
        margin-bottom: 20px;
      }

      .icons {
        > div {
          margin: 0 18px;
        }
      }
    }
  }
}

.modal {
  > .modal-dialog {
    > .modal-content {
      .modal-header + .modal-footer {
        margin-top: 15px;
      }
    }
  }
}

@media (max-width: 767px) {
  .modal {
    > .modal-dialog {
      margin: 10px 10px 92px; // Makes room for hubspot chat
    }
  }
}

@media (min-width: 1281px) {
  .modal-xl {
    width: 1260px;
  }
}

@media (max-width: 1280px) {
  .modal-xl {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.content-box {
  background: white;
  padding: 12px 24px 24px;
  position: relative;
  /*width: 100%;
  float: left;*/
}

.container-xs {
  max-width: 480px;
  padding-top: 16px;
}

@media (max-width: 768px) {
  .content-box {
    margin: 0 -15px;
    //padding: 0px 12px 24px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media (min-width: 768px) {
  .content-box {
    margin: 16px 0;
    border-radius: 8px;
    @include box-shadow(2px 3px 10px 2px rgba(0, 0, 0, 0.2));

    // border-radius: 4px;
    // @include box-shadow(2px 3px 10px 2px rgba(150, 150, 150, .03));
  }
}

// Flex container
.flex-center-distributed {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.spaced {
    > * + * {
      margin-left: 12px;
    }
  }
  &.spaced-10 {
    > * + * {
      margin-left: 10px;
    }
  }

  .grow {
    flex-grow: 1;
  }
}

/** Col highlight */
.container,
.container-fluid {
  .row {
    .col-highlight {
      background-color: #f2f4f5;
      border-radius: 6px;
      padding-top: 15px;
      margin-bottom: 15px;
    }
  }
}

/** Print */
@media print {
  // @page {
  //   size: A4;
  // }

  @for $i from 1 through 12 {
    .col-sm-#{$i} {
      width: #{percentage(math.div(round($i * 8.33), 100))};
      float: left;
    }
  }

  a[href]:after {
    display: none;
  }

  .img {
    print-color-adjust: exact;
  }

  .container {
    width: auto !important;
  }

  button,
  messenger,
  filter-set,
  .attach-icon,
  .app-header {
    display: none !important;
  }

  .sticky {
    position: relative !important;
  }

  .scrollable-pane {
    height: auto !important;
    overflow-y: visible !important;
  }

  app-root {
    .app-content {
      flex: none;
      overflow-y: visible !important;
      overflow-x: visible !important;
    }
  }
}

/** translucent backgrounds */
.backdrop-glass {
  backdrop-filter: blur(2px) brightness(90%) saturate(70%);
}

body.messenger-open {
  // Stuff to hide when Messenger is open
  > #hubspot-messages-iframe-container {
    display: none !important;
  }
}

.clipboard-ready {
  cursor: auto !important;
  user-select: auto !important;

  .material-symbols-rounded {
    display: none !important;
  }

  * {
    cursor: auto !important;
    user-select: auto !important;
  }
}

.m-b1 {
  margin-bottom: 1rem;
}

.m-b2 {
  margin-bottom: 2rem;
}

.m-b3 {
  margin-bottom: 3rem;
}

.m-b4 {
  margin-bottom: 4rem;
}

.p-t0 {
  padding-top: 0;
}

.p-t1 {
  padding-top: 1rem;
}

.p-t2 {
  padding-top: 2rem;
}

.p-t3 {
  padding-top: 3rem;
}

.p-t4 {
  padding-top: 4rem;
}
