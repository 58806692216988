// Order matters, .agree-theme must to be the first one
.agree-theme {
  @import "./agree";
}

// .sancor-theme {
//   @import "./sancor";
// }

// .colombian-theme {
//   @import "./colombian";
// }

.santander-mx-theme {
  @import "./santander-mx";
}

// .macro-ar-theme {
//   @import "./macro-ar";
// }

.woccu-theme {
  @import "./woccu";
}

.agrology-theme {
  @import "./agrology";
}
