//== Font configuration
$font-basic: "Lato", Helvetica, Arial, sans-serif;
$font-heading: "Poppins", Helvetica, Arial, sans-serif;
$font-monospace: monospace;

//== Logo configuration
$logo-path: url("/assets/img/logo.png");
$logo-white-path: url("/assets/img/home/logo-agree-market-w.png");
$logo-black-path: url("/assets/img/home/logo-agree-market-w.png");
$logo-aspect-ratio: 3.2;

//== Agree core default values
$primary: #32e8ba;
$secondary: #32e8ba;

//== Status colors
$status-info-color: #00838f;
$status-info-border: #80deea;
$status-info-bg: #e0f7fa;

$status-pending-color: #e6a800;
$status-pending-border: #ffcc80;
$status-pending-bg: #fff3e0;

$status-warning-color: #e65100;
$status-warning-border: #ffcc80;
$status-warning-bg: #fff3e0;

$status-danger-color: #a94442;
$status-danger-border: #ce8382;
$status-danger-bg: #ffebee;

$status-success-color: #1b5e20;
$status-success-border: #a5d6a7;
$status-success-bg: #e8f5e9;

$alert-bg-scale: -80% !default;
$alert-border-scale: -70% !default;
$alert-color-scale: 40% !default;

@mixin generateSASSThemeVariables($theme) {
  //== Fonts
  $font-basic: get-value($theme, font-basic, $font-basic) !global;
  $font-heading: get-value($theme, font-heading, $font-heading) !global;
  $font-monospace: get-value($theme, font-monospace, $font-monospace) !global;

  //== Logo configuration
  $logo-path: get-value($theme, logo-path, $logo-path) !global;
  $logo-black-path: get-value($theme, logo-black-path, $logo-black-path) !global;
  $logo-white-path: get-value($theme, logo-white-path, $logo-white-path) !global;
  $logo-aspect-ratio: get-value($theme, logo-aspect-ratio, $logo-aspect-ratio) !global;

  //== Colors
  $primary: darken(map-get($theme, primary), 10%) !global;
  $primary-darken-1: scale-color(map-get($theme, primary), $lightness: -31%) !global;
  $primary-darken-2: scale-color(map-get($theme, primary), $lightness: -55%) !global;
  $primary-darken-3: scale-color(map-get($theme, primary), $lightness: -85%) !global;
  $primary-lighten-1: scale-color(map-get($theme, primary), $lightness: 70%) !global;
  $primary-lighten-2: scale-color(map-get($theme, primary), $lightness: 94%) !global;
  $primary-lighten-3: scale-color(map-get($theme, primary), $lightness: 98%) !global;

  $secondary: darken(if(map-get($theme, secondary), map-get($theme, secondary), $secondary), 10%) !global;
  $secondary-darken-1: darken(
    darken(if(map-get($theme, secondary), map-get($theme, secondary), $secondary), 10%),
    15%
  ) !global;
  $secondary-darken-2: darken(
    darken(if(map-get($theme, secondary), map-get($theme, secondary), $secondary), 10%),
    20%
  ) !global;
  $secondary-darken-3: darken(
    darken(if(map-get($theme, secondary), map-get($theme, secondary), $secondary), 10%),
    25%
  ) !global;

  //== Business colors
  $buy-agree: #3d8eb9 !global;
  $sell-agree: #ff4d4d !global;
  $booked-agree: #d0d6e2 !global;

  //== Status colors
  $status-info-color: if(map-get($theme, info), map-get($theme, info), $status-info-color) !global;
  $status-info-border: if(
    map-get($theme, info),
    shift-color(map-get($theme, info), $alert-border-scale),
    $status-info-border
  ) !global;
  $status-info-bg: if(
    map-get($theme, info),
    shift-color(map-get($theme, info), $alert-bg-scale),
    $status-info-bg
  ) !global;

  $status-pending-color: if(map-get($theme, pending), map-get($theme, pending), $status-pending-color) !global;
  $status-pending-border: if(
    map-get($theme, pending),
    shift-color(map-get($theme, pending), $alert-border-scale),
    $status-pending-border
  ) !global;
  $status-pending-bg: if(
    map-get($theme, pending),
    shift-color(map-get($theme, pending), $alert-bg-scale),
    $status-pending-bg
  ) !global;

  $status-warning-color: if(map-get($theme, warning), map-get($theme, warning), $status-warning-color) !global;
  $status-warning-border: if(
    map-get($theme, warning),
    shift-color(map-get($theme, warning), $alert-border-scale),
    $status-warning-border
  ) !global;
  $status-warning-bg: if(
    map-get($theme, warning),
    shift-color(map-get($theme, warning), $alert-bg-scale),
    $status-warning-bg
  ) !global;

  $status-danger-color: if(map-get($theme, danger), map-get($theme, danger), $status-danger-color) !global;
  $status-danger-border: if(
    map-get($theme, danger),
    shift-color(map-get($theme, danger), $alert-border-scale),
    $status-danger-border
  ) !global;
  $status-danger-bg: if(
    map-get($theme, danger),
    shift-color(map-get($theme, danger), $alert-bg-scale),
    $status-danger-bg
  ) !global;

  $status-success-color: if(map-get($theme, success), map-get($theme, success), $status-success-color) !global;
  $status-success-border: if(
    map-get($theme, success),
    shift-color(map-get($theme, success), $alert-border-scale),
    $status-success-border
  ) !global;
  $status-success-bg: if(
    map-get($theme, success),
    shift-color(map-get($theme, success), $alert-bg-scale),
    $status-success-bg
  ) !global;

  //== Buttons
  $btn-agree-color: if(map-get($theme, btn-agree-color), map-get($theme, btn-agree-color), #fff) !global;
  $btn-agree-bg: $primary !global;
  $btn-agree-border: darken($btn-agree-bg, 5%) !global;

  //== Animations and transitions
  $panel-transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1) !global;

  //== Labels
  $label-buy-bg: $buy-agree !global;
  $label-sell-bg: $sell-agree !global;
  $label-booked-bg: $booked-agree !global;
}

@mixin generateCSSThemeVariables() {
  //== Fonts
  --font-basic: #{$font-basic};
  --font-heading: #{$font-heading};

  //== Logo
  --logo-path: #{$logo-path};
  --logo-black-path: #{$logo-black-path};
  --logo-white-path: #{$logo-white-path};
  --logo-aspect-ratio: #{$logo-aspect-ratio};

  //== Colors
  --primary: #{$primary} !important;
  --primary-darken-1: #{$primary-darken-1};
  --primary-darken-2: #{$primary-darken-2};
  --primary-darken-3: #{$primary-darken-3};
  --primary-lighten-1: #{$primary-lighten-1};
  --primary-lighten-2: #{$primary-lighten-2};
  --primary-lighten-3: #{$primary-lighten-3};

  --secondary: #{$secondary} !important;
  --secondary-darken-1: #{$secondary-darken-1};
  --secondary-darken-2: #{$secondary-darken-2};
  --secondary-darken-3: #{$secondary-darken-3};

  //== Business colors
  --buy-agree: #{$buy-agree};
  --sell-agree: #{$sell-agree};
  --booked-agree: #{$booked-agree};

  //== Status colors
  --status-info-color: #{$status-info-color};
  --status-info-border: #{$status-info-border};
  --status-info-bg: #{$status-info-bg};

  --status-pending-color: #{$status-pending-color};
  --status-pending-border: #{$status-pending-border};
  --status-pending-bg: #{$status-pending-bg};

  --status-warning-color: #{$status-warning-color};
  --status-warning-border: #{$status-warning-border};
  --status-warning-bg: #{$status-warning-bg};

  --status-danger-color: #{$status-danger-color};
  --status-danger-border: #{$status-danger-border};
  --status-danger-bg: #{$status-danger-bg};

  --status-success-color: #{$status-success-color};
  --status-success-border: #{$status-success-border};
  --status-success-bg: #{$status-success-bg};

  //== Buttons
  --btn-agree-color: #{$btn-agree-color};
  --btn-agree-bg: #{$btn-agree-bg};
  --btn-agree-border: #{$btn-agree-border};

  //== Animations and transitions
  --panel-transition: #{$panel-transition};

  //== Labels
  --label-buy-bg: #{$label-buy-bg};
  --label-sell-bg: #{label-shell-bg};
  --label-booked-bg: #{label-booked-bg};
}

@function get-value($map, $key, $default) {
  @if (map-has-key($map, $key)) {
    @return map-get($map, $key);
  } @else {
    @return $default;
  }
}

// Shade the color if the weight is positive, else tint it
@function tint-color($color, $weight) {
  @return mix(white, $color, $weight);
}

@function shift-color($color, $weight) {
  @return if($weight > 0, shade-color($color, $weight), tint-color($color, -$weight));
}

@mixin alert-variant($background, $border, $color) {
  color: shade-color($color, 20%);
  background: $background;
  border-color: $border;
}

// Shade a color: mix a color with black
@function shade-color($color, $weight) {
  @return mix(black, $color, $weight);
}

@mixin status($color, $border, $bg) {
  color: $color;
  border-color: $border;
  background-color: $bg;
}
