@at-root {
  @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
}

$theme-config: (
  primary: #2a6bd3,
  font-heading: #{"Montserrat, sans-serif"},
  font-basic: #{"Montserrat, sans-serif"},
  // success: rgb(14, 197, 51),
  logo-path: url("/assets/img/theme/woccu/worldcouncil_logo_2020_es.svg"),
  logo-black-path: url("/assets/img/theme/woccu/woccu-b.png"),
  logo-white-path: url("/assets/img/theme/woccu/woccu-w.png"),
  logo-aspect-ratio: calc(100 / 21)
);

@include generateSASSThemeVariables($theme-config);
@include generateCSSThemeVariables();

@import "../bootstrap_variables";
@import "../agree_styles";

.landing-bg {
  > .overlay {
    background-color: rgba(0, 0, 0, 0.4);
  }
  > .background-images {
    > .field {
      background-image: none;
      // background-position: left center;
      background-color: #fff;
    }
    > .teamwork {
      background-image: url("/assets/img/theme/woccu/background.jpeg");
      background-position: right bottom;
      width: 100%;
      // background-size: 70%;
      background-color: #fff;
    }
  }
}
