// Tenemos que pisar los valores default de bootstrap para botones y
// formularios. Tenemos que definir estas variables para que se vean como
// nuestro diseño:

//== Colors

$gray-dark: #333; //#60646d;

//$brand-primary: darken(#50ebc4, 6.5%);  // turquesa Agree
$success: #27ae60; // verde Agree
//$brand-info:    darken(#50ebc4, 6.5%);  // turquesa Agree
$warning: #ff7416; // naranja Agree
$danger: #ff4d4d; // rojo agree
$primary: #50ebc4; // turquesa Agree

$state-warning-text: #f56200;
$state-warning-bg: #fef3eb;

//== Typography

$font-family-sans-serif: var(--font-basic);
$headings-font-family: var(--font-heading);

$headings-font-weight: 400;

//== Components

//$padding-base-vertical: 3px;

//== Scaffolding

$body-bg: #f2f2f2;

//$link-color:            darken($brand-primary, 7%);
//$link-hover-color:      darken($link-color, 7%);
//$link-hover-decoration: none;

//== Forms

//$form-group-margin-bottom: 24px;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

//$state-success-text: #3c763d;
//$state-success-bg: #dff0d8;
//$state-success-border: darken(spin(@state-success-bg, -10), 5%);
//$state-info-text: #31708f;
//$state-info-bg: #d9edf7;
//$state-info-border: darken(spin(@state-info-bg, -10), 7%);
// $state-warning-text: $warning;
// $state-warning-bg: lighten($state-warning-text, 20%);
// $state-warning-border: darken($state-warning-text, 10%);
//$state-danger-text: #a94442;
//$state-danger-bg: #f2dede;
//$state-danger-border: darken(spin(@state-danger-bg, -10), 5%);

//== Buttons

//$btn-font-weight:    bold;
//$btn-default-color:  white;
//$btn-default-bg:     #B3B3B3;
//$btn-primary-bg:     #428bca; // azul bootstrap
//$btn-default-border: transparent;
//$btn-primary-border: transparent;
//$btn-success-border: transparent;
//$btn-info-border:    transparent;
//$btn-warning-border: transparent;
//$btn-danger-border:  transparent;

//== Dropdowns
$dropdown-border: #eee;

//== Navbar
$navbar-margin-bottom: 0;
$navbar-default-bg: white;
$navbar-default-toggle-border-color: transparent;

//== List group
$list-group-bg: transparent;

//== Breadcrumbs
//$breadcrumb-padding-vertical: 0;
//$breadcrumb-padding-horizontal: 0;
$breadcrumb-bg: lighten(#eceff1, 2%);

//== Panels
//$panel-default-heading-bg: lighten(#eceff1, 2%);

// Components w/ JavaScript

//== Modals

//$modal-content-border-color: transparent;
//$modal-content-fallback-border-color: transparent;
//$modal-backdrop-opacity: .4;
//$modal-inner-padding: 0 15px 15px;
$modal-title-padding: 15px 15px 0;
$modal-header-border-color: transparent;
$modal-footer-border-color: #e5e5e5;

//== Tabs

// https://stackoverflow.com/questions/40526233/angular2-bootstrap-sass-compilation-error-using-angular-cli#40530477
$icon-font-path: "bootstrap-sass/assets/fonts/bootstrap/";

bs-dropdown-container {
  z-index: 102;
}
