* {
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      outline: none !important;
    }
  }
}

ins {
  color: black;
  background: #bbffbb;
}

del {
  color: black;
  background: #ffbbbb;
}

a {
  cursor: pointer;
  color: #285f8f; // Attempt to improve contrast

  &.agree-color {
    color: var(--primary); //$link-agree;

    &:hover {
      color: var(--primary-darken-1);
    }
  }
}

// Rows and cols
.nomargin-top {
  margin-top: 0;
}

// Buy and sell
.buy-bg {
  background-color: $label-buy-bg;
}
.sell-bg {
  background-color: $label-sell-bg;
}
.booked-bg {
  background-color: $label-booked-bg;
  color: #000 !important;
}
.buy {
  color: $label-buy-bg;
}
.sell {
  color: $label-sell-bg;
}
.booked {
  color: $label-booked-bg;
}

// Alerts
.alert hr {
  margin: 7px 0;
}

.divider-break {
  text-align: center;
  position: relative;
  float: left;
  width: 100%;
  padding: 16px 0;
  line-height: 0;

  span {
    line-height: 1;
    color: #767676;
    font-weight: 400;
    z-index: 2;
    position: relative;
    display: inline-block;
    background-color: #f2f2f2;
    padding: 0 8px 0 7px;
  }

  &:after {
    content: "";
    width: 100%;
    background-color: transparent;
    display: block;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    // margin-top: -1px;
    z-index: 1;
  }
}

/* Headers */
.sticky {
  position: sticky;
  z-index: 100;
  top: 0;
  // background-color: #fff;
}

.ag-header {
  display: flex;
  padding: 10px 0 12px;
  margin-top: -10px;
  align-items: center;
  justify-content: space-between;

  &.sticky {
    background-color: #fff;
  }

  .ag-header-title {
    display: flex;
    align-items: center;

    h3,
    h4 {
      margin: 0px;
    }
  }
  .ag-header-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .ag-header {
    flex-flow: wrap;

    > * {
      width: 100%;
      
      + * {
        margin-top: 12px;
      }
    }
  }
}

// para que los Material Symbols Rounded queden bien en linea y tamaño proporcional a la fuente que acompaña
.material-symbols-rounded {
  font-size: 140%;
  vertical-align: bottom;
}

// Image Icons
.image-icons {
  // background-color: #0e8164;
  width: 1.2em;
  height: 1.2em;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: top;

  &.cash-payment {
    background-image: url("/assets/img/icons/cash-delivery.png");
  }
}

// Footer
footer {
  margin: 20px 0;
}

.tab-footer {
  padding-top: 15px;
  text-align: right;

  .btn + .btn {
    margin-left: 5px;
  }
}

.clickable {
  cursor: pointer;
  user-select: none;
}

// General
@import "_layout.scss";
@import "_widgets.scss";
@import "_tables.scss";
@import "_animations.scss";

// Core CSS
@import "_type.scss";
@import "_forms.scss";
@import "_buttons.scss";

@at-root {
  @import "external_libraries";
}