
// ngx-jsoneditor - ang-jsoneditor
@import "jsoneditor/dist/jsoneditor.min.css";

// .ace_editor.ace-jsoneditor {
//   min-height: 500px
// }
div.jsoneditor {
  border: none !important;
  background: #f9f9f9 !important;
}
div.jsoneditor-mode-text {
  height: 500px; //100vh if you want full screen
}
div.jsoneditor-mode-code {
  height: 500px; //100vh if you want full screen
}
div.jsoneditor-mode-form {
  height: 500px; //100vh if you want full screen
}

// Quill
@import "quill/dist/quill.core.css";
// @import '~quill/dist/quill.bubble.css';
@import "quill/dist/quill.snow.css";

quill-editor {
  display: initial !important;

  > .ql-snow {
    &.ql-toolbar {
      border-radius: 4px 4px 0 0;
      background-color: #f6f6f6;
    }
    &.ql-container {
      border-radius: 0 0 4px 4px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      font-family: inherit;

      &.renderer {
        border-radius: 4px;
        margin-bottom: 20px;
        background-color: #f9fbfb;
        // overflow: hidden;
        padding: 12px;

        > .ql-editor {
          min-height: 0px;
          max-height: 500px;
        }
      }

      > .ql-editor {
        padding: 6px 12px;
        min-height: 74px;
        max-height: 250px;

        &.ql-blank::before {
          font-style: normal;
          color: #a2a2a2;
          left: 12px;
          right: 12px;
        }
      }
    }
  }
}
