/* Tables */
table.table thead {
  background-color: #f6f6f6;

  th {
    text-transform: uppercase;
    font-size: 66.66%;
  }
}

.table {
  thead th {
    white-space: nowrap;
  }

  /* Nested tables */
  .table {
    background-color: #fafafa;
  }

  th,
  td {
    &.dlb {
      border-left: 1px dotted rgba(96, 100, 109, 0.2);
      // border-left: 1px dotted #ccc;
    }

    &.drb {
      border-right: 1px dotted rgba(96, 100, 109, 0.2);
      // border-right: 1px dotted #ccc;
    }

    &.sap {
      // Small as possible
      width: 1px;
      // white-space: nowrap;
    }

    &.no-vertical-padding {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  > tbody > {
    tr {
      &.product-header > th {
        border: none;
        background-color: #4b515d;
        font-weight: normal;

        color: #fff;
        font-size: 75%;
        padding-left: 10px;
      }

      &.tr-danger {
        border-left: 6px solid #a94442;
      }
    }
  }

  .td-empty {
    border: none;
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  .table {
    th,
    td {
      &.sap {
        // Small as possible
        // width: 1px;
        white-space: nowrap;
      }
    }
  }
}

/* Summary setion */

.summary {
  font-size: 110%;
  font-weight: 300;

  .quantity {
    font-weight: 400;
  }
}

/* General tables */

.horizontal-scroller {
  overflow-x: auto;
  scrollbar-width: none;

  // Hide horizontal scrollbar on windows
  // &::-webkit-scrollbar {
  //   display: none;
  // }

  > table {
    width: auto;
    max-width: none;
    min-width: 100%;
  }
}

table tr.tr-align-center > {
  th,
  td {
    height: 1px;
    vertical-align: middle;
  }
}

/* Columns */
.table {
  td[headers~="ico"],
  .td-icons,
  td[headers~="obs"] {
    width: 16px;
    text-align: center;
    font-size: 85%;
    color: #808389;
    /*line-height: 1.6;*/
  }

  td[headers~="ton"],
  th#ton {
    width: 60px;
    white-space: nowrap;
  }

  td[headers~="act"] {
    width: 70px;
  }

  td[headers~="cad"] {
    width: 30px;
    color: #0e8164;
  }

  .td-status {
    width: 99%;
    /* Try to fill the available space */
  }
}

@media (max-width: 768px) {
  .table-condensed > {
    thead > tr > {
      th,
      td {
        padding: 3px 2px;
      }
    }

    tbody > tr > {
      th,
      td {
        padding: 3px 2px;
      }
    }

    tfoot > tr > {
      th,
      td {
        padding: 3px 2px;
      }
    }
  }

  .table {
    .td-icons i + i {
      margin-top: 4px;
    }

    td {
      &[headers~="ico"] i + i {
        margin-top: 4px;
      }

      &[headers~="ton"] {
        width: auto;
      }
    }

    th#ton {
      width: auto;
    }

    .td-company {
      min-width: 0px;
    }
  }
}

/*

Location

*/

.table .location-small {
  font-size: 75%;
  font-weight: normal;
  color: #757575;
}

@media (max-width: 768px) {
  .traded-location {
    max-width: 140px;
  }
}

/*

Pricing

*/

.price-box {
  position: relative;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 8px 4px 3px;
  width: 130px;
  /*background: #ff4d4d;*/
  color: #fff;
  display: inline-block;

  .type {
    position: absolute;
    top: 2px;
    left: 4px;
    font-size: 50%;
    font-weight: bold;
    text-transform: uppercase;
  }

  .value {
    text-align: right;
    /*min-width: 200px;*/
  }
}

@media (max-width: 768px) {
  .price-box {
    width: 74px;

    .currency {
      display: block;
    }
  }
}

/* Checkbox */
table {
  tr > {
    th,
    td > {
      input[type="checkbox"].no-margin {
        vertical-align: middle;
      }
    }
  }
}

@media (min-width: 768px) {
  table > {
    thead,
    tbody {
      > tr {
        > {
          td,
          th {
            > {
              div .show-onhover,
              agree-confirm .show-onhover,
              .show-onhover {
                &:not(:checked) {
                  visibility: hidden;
                }
              }
            }
          }
        }

        &:hover {
          > {
            td,
            th {
              > {
                div .show-onhover,
                agree-confirm .show-onhover,
                .show-onhover {
                  visibility: visible;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Order view and preview */

table.table.table-detail > thead {
  background-color: transparent;

  > tr > th {
    color: #707070;
  }
}

.row-main table.table.table-detail td {
  /*border-top-style: none;*/
  font-size: 110%;
}

/* Mini table */

table.mini-table {
  font-size: 80%;
  width: 100%;

  th,
  td {
    padding: 3px 8px;
  }

  > {
    thead {
      background-color: transparent;

      > tr {
        border-bottom: 2px solid #ddd;
      }
    }

    tbody > tr + tr {
      border-top: 1px dotted #ddd;
    }
  }
}

// Specs table

table.table-specs {
  // thead th {
  //   white-space: initial;
  // }
  caption {
    padding-top: 0px;
  }

  thead > tr > th {
    white-space: normal !important;
  }

  td,
  thead > tr > th {
    text-align: center;
    vertical-align: middle;
  }

  tr {
    td + td,
    th + th {
      border-left: 1px solid #ddd;
    }
  }
}

// Scrollable table with fix headers
@media (min-width: 768px) {
  .table-scroll {
    position: relative;
    width: 100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    // height: calc(100vh - 195px);
    margin-bottom: 20px;
    -webkit-overflow-scrolling: touch;

    table {
      width: 100%;
      margin-bottom: 0;

      th {
        background-color: #f4f6f6;
      }

      .s {
        position: sticky;

        &.t {
          top: 0;
          z-index: 5;

          &.l {
            z-index: 6 !important;
          }
        }

        &.l {
          left: 0;
          z-index: 2;
        }

        &.w {
          background-color: #fff;
        }

        &.g {
          background-color: #f6f6f6;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .table-sticky-headers {
    // Default height
    max-height: calc(100vh - 214px);

    > table {
      border-collapse: separate;

      > thead > tr > th {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: #f6f6f6;
      }
    }
  }
}
