/* Tipo */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  letter-spacing: -0.5px;

  &.grayed {
    background-color: #f2f4f5;
    padding: 0.75em 0.5em;
    margin: 0 0 10px;
  }

  > .label {
    font-family: $font-basic;
    font-size: 65%;
    vertical-align: middle;
  }
}

.brand-color {
  color: var(--primary);
}

.biggest {
  font-size: 200%;
}
.bigger {
  font-size: 133%;
}
.micro {
  font-size: 70%;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;

  &.max-100 {
    display: inline-block;
    max-width: 100px;
  }
}
.lighten {
  font-weight: 400;
  // font-size: 85%;
}
.no-select {
  user-select: none;
}
.order-link {
  display: flex;
}
.order-action {
  font-size: 80%;
  margin-left: 1em;
  display: inline-block;
}
.currency {
  font-size: 75%;
  opacity: 0.9;
}
.faded {
  opacity: 0.5;
}
.highlighted-text {
  background-color: rgb(255 213 80 / 18%);
  border-bottom: 2px dotted #ffd54f;
  margin-bottom: -2px;
}

span.not-available {
  background-color: $gray-lighter;
  color: $gray-lighter;
  padding: 0 4em;

  * {
    background-color: $gray-lighter;
    color: $gray-lighter;
  }
}

.widther {
  margin-left: 2ex;
  margin-right: 2ex;
}

.clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 768px) {
  .order-link {
    > .truncate {
      flex-grow: 1;
      width: 60px;
    }
  }
}

.price-tag {
  white-space: nowrap;

  .cents {
    font-size: 70%;
    margin-left: 2px;
    vertical-align: text-top;
  }
}

.lh-1 {
  line-height: 1em;
}

.monospace {
  font-family: monospace;

  &.wrap {
    display: block;
    white-space: pre-wrap;
    // word-break: break-all;
    word-wrap: break-word;
    overflow: auto;
  }
}
