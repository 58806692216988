// acciones principales con el color de Agree
.btn-agree {
  @include button-variant($btn-agree-color, $btn-agree-bg, $btn-agree-border);
  text-shadow: 0px 1px 1px rgba(0,0,0,0.33);

  &:focus {
    background-color: $btn-agree-bg !important;
  }
}

// FAS-1908 Bootstrap Dropdown FIX
.btn-group {
  &.open {
    z-index: 3 !important;
  }
}

.dropdown-menu > .disabled {
  cursor: not-allowed;

  > a {
    pointer-events: none;
  }
}