@at-root {
  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Open+Sans:wght@300;400;600;700;800&family=Poppins:wght@300;400;500&display=swap');
}

$theme-config: (
  primary: #32e8ba,
  logo-aspect-ratio: 4.4
);

@include generateSASSThemeVariables($theme-config);
@include generateCSSThemeVariables();

@import "../agree_styles";