@at-root {
  // Headline
  @font-face {
    font-family: "SantanderFont-heading";
    src:
      url("../../fonts/themes/santander-mx/SantanderHeadlineW05-Rg.woff") format("woff"),
      url("../../fonts/themes/santander-mx/SantanderHeadlineW05-Rg.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }

  // Basic text
  @font-face {
    font-family: "SantanderFont-basic";
    src:
      url("../../fonts/themes/santander-mx/SantanderMicroTextW05-Rg.woff") format("woff"),
      url("../../fonts/themes/santander-mx/SantanderMicroTextW05-Rg.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "SantanderFont-basic";
    src: url("../../fonts/themes/santander-mx/SantanderMicroTextW05-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
  }
}

$theme-config: (
  primary: rgb(236, 0, 0),
  font-heading: #{"SantanderFont-heading, Lato, Helvetica, sans-serif"},
  font-basic: #{"SantanderFont-basic, Lato, Helvetica, sans-serif"},
  success: rgb(14, 197, 51),
  logo-path: url("/assets/img/theme/santander-mx/logo.svg"),
  logo-black-path: url("/assets/img/theme/santander-mx/logo-b.png"),
  logo-white-path: url("/assets/img/theme/santander-mx/logo-w.png"),
  logo-aspect-ratio: 4.4
);

@include generateSASSThemeVariables($theme-config);
@include generateCSSThemeVariables();

@import "../bootstrap_variables";
@import "../agree_styles";

.landing-bg {
  > .overlay {
    background-color: rgba(0, 0, 0, 0.4);
  }
  > .background-images {
    > .field {
      background-image: none;
      // background-position: left center;
      background-color: #fff;
    }
    > .teamwork {
      background-image: url("/assets/img/home/bg-santander@4k.png");
      background-position: right bottom;
      width: 100%;
      background-size: 70%;
      background-color: #fff;
    }
  }
}