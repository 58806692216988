/* Intro JS */
.agree-introjs {
  &.introjs-tooltip {
    min-width: 280px;
  }

  .introjs-tooltiptext {
    padding: 10px;

    h5 {
      padding-bottom: 10px;
      border-bottom: 1px solid var(--primary);
    }
  }

  .introjs-tooltipbuttons {
    .introjs-skipbutton {
      // display: none;
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      border: none;
    }
  }
}

/* Date picker */
input.form-control[readonly] {
  &[bsDaterangepicker],
  &[bsDatepicker],
  &.not-grayed {
    background-color: inherit;
  }
}

bs-datepicker-container,
bs-daterangepicker-container {
  .bs-datepicker {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }

  .theme-green {
    .bs-datepicker-head {
      background-color: var(--primary);
    }

    .bs-datepicker-body table td {
      &.week span {
        color: var(--primary);
      }

      span:before {
        left: -7px;
        right: -7px;
      }

      span.selected,
      &.selected span,
      span[class*="select-"]:after,
      &[class*="select-"] span:after {
        background-color: var(--primary);
      }
    }
  }
}

@media (max-width: 768px) {
  .bs-datepicker-multiple {
    .bs-datepicker-head {
      height: 40px;
      padding: 4px 10px;
    }

    .bs-datepicker-body {
      min-height: 0;

      table.days span {
        height: 26px;
        line-height: 26px;
      }
    }
  }
}

.card {
  cursor: pointer;
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-bottom: 24px;
  // z-index: 1;

  transition-property: border-color, margin-top, box-shadow;
  transition-duration: 0.15s;
  box-shadow: 2px 3px 6px 2px rgba(150, 150, 150, 0.1);

  &:hover {
    border-color: var(--primary);
    box-shadow: 2px 3px 16px 2px rgba(150, 150, 150, 0.3);
    background-color: var(--primary-lighten-2);
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    background-color: #eee;

    > .header,
    > .body {
      opacity: 0.4;
    }
  }

  &.card-sm {
    --logo-size: 50px;

    margin-bottom: 20px;

    .header {
      height: 35px;
      justify-content: flex-end;

      .logo-circle {
        width: var(--logo-size);
        height: var(--logo-size);
        margin-top: 10px;

        &.image {
          > div.logo {
            width: calc(var(--logo-size) - 2px);
            height: calc(var(--logo-size) - 2px);
          }
        }
      }
    }

    > .body {
      position: relative;
      // padding-top: calc(var(--logo-size) - 18px);
      padding-top: 10px;

      h3,
      h4,
      h5 {
        text-align: left;
        padding-bottom: 8px;
      }

      > .description {
        -webkit-line-clamp: 4;
        // text-align: left;
        height: 6.6em;
        padding-top: 1em;
        margin-bottom: 0.5em;
        border-top: 1px solid #cfd8dc;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      > .btn {
        position: absolute;
        bottom: 4px;
        right: 0;
      }
    }

    &.selected {
      box-shadow: inset 0 0 0px 4px var(--primary);
      background-color: var(--primary-lighten-3);
      // pointer-events: none;
    }
  }

  .header {
    display: flex;
    justify-content: center;
    padding: 0px 20px;
    width: 100%;
    height: 90px;
    background: transparent center top no-repeat;
    background-size: cover;

    .shadow {
      box-shadow: 2px 3px 10px 2px rgba(150, 150, 150, 0.1);
    }

    .logo-circle {
      border: 1px solid #ccc;
      margin-top: 50px;
      width: 80px;
      height: 80px;
      border-radius: 50%;

      &.image {
        position: relative;
      }

      &.image > div.logo {
        width: 78px;
        height: 78px;
        border-radius: 50%;
      }
    }
  }

  .body {
    padding: 52px 20px 20px;

    h3,
    h4,
    h5 {
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      padding-bottom: 16px;
    }

    hr {
      margin: 0 0 22px;
      border-color: rgba(0, 0, 0, 0.06);
    }
  }
}

@media (min-width: 992px) {
  .card {
    margin: 0;
  }
  .stretch-items {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
  }
  .stretch-items > div {
    margin-bottom: 24px;
  }
}

/* Loading Spinner container */
.loading {
  padding: 9.5em 2em;
  position: relative;

  &.loading-sm {
    padding: 2em;
  }
}

/* No data container */
.no-data {
  border: 3px dashed $gray-lighter;
  border-radius: 1em;
  text-align: center;
  padding: 10em 2em;
  color: $gray-light;
  position: relative;
  margin-top: 12px;

  &.no-data-sm {
    padding: 5em 2em;
  }

  > i {
    opacity: 0.1;
    margin: auto;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 1em;
    font-size: 700%;
    pointer-events: none;
  }

  > * {
    position: relative;
    z-index: 2;
  }
}

/* Counterparts list */
.list-group.list-group-condensed .list-group-item {
  padding: 6px 8px;
  border-style: dotted;
}

/* Breadcrumb */
ol.breadcrumb {
  margin-bottom: 10px;

  > li {
    scope-icon > i {
      margin-right: 4px;
    }
  }
}

/* Counterorder status */
.co-status > div {
  font-size: 80%;
  padding: 5px 8px;
  border-style: dotted;
  border-width: 1px;
  border-radius: 4px 3px 3px 4px;
  position: relative;
  max-width: 500px;
  cursor: pointer;

  &.status-success > span:before {
    content: "check_circle_outline";
  }

  &.status-warning > span:before {
    content: "warning";
  }

  &.status-danger > span:before {
    content: "highlight_off";
  }

  &.status-disabled > span:before {
    content: "check";
  }

  &.status-info > span:before {
    /*content: 'play_circle_outline';*/
    content: "send";
  }

  > span:before {
    font-family: "Material Symbols Rounded";
    font-size: 140%;
    vertical-align: bottom;
    line-height: 1;
    text-transform: none;
    font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
    margin-right: 3px;
    opacity: 0.66;
  }
}

.status-success {
  @include status($status-success-color, $status-success-border, $status-success-bg);
}
.status-info,
.status-default {
  @include status($status-info-color, $status-info-border, $status-info-bg);
}
.status-warning {
  @include status($status-warning-color, $status-warning-border, $status-warning-bg);
}
.status-danger {
  @include status($status-danger-color, $status-danger-border, $status-danger-bg);
}
/*.co-status > div.status-disabled {
    opacity: 0.3;
  }*/

/* Order status */
.status {
  > .alert {
    padding: 8px 15px;
    margin: 8px 0 10px;
    border-radius: 0px;
    border-style: solid none;

    a:hover,
    a:focus {
      text-decoration: none;
    }
  }
}

/* Badges */
.badge {
  &.badge-danger {
    background-color: $danger;
  }
  &.badge-warning {
    background-color: $warning;
  }
  &.badge-info {
    background-color: $brand-info;
  }
  &.badge-success {
    background-color: $success;
  }
  &.badge-sup {
    position: relative;
    top: -0.8em;
    left: -1em;
    font-size: 66%;
    margin-right: -1em;
  }
}

/* Logo Circle */
.logo-circle {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 50%;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;

  // &.logo-circle.n-e{
  //   text-align: center;
  //   background-color: white;
  // }
  &.text {
    background-color: #d3e3ec;
    color: #3d8eb9;
  }
  &.image {
    border: 1px solid #ccc;
  }
  &.image > div {
    width: 22px;
    height: 22px;
    border-radius: 11px;
    border: 3px solid white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

/* Scrollable pane */
.scrollable {
  height: 50vh;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 32px;
  padding: 1em 8px 1em 1em;

  > .content {
    height: 100%;
    padding-right: 2em;
    overflow-y: scroll;
    -ms-overflow-style: none; /* Edge */
    overflow: -moz-scrollbars-none; /* Firefox */

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
      height: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bbb;
      border-radius: 4px;
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: #3de9be;
    }
    &::-webkit-scrollbar-track {
      background-color: #eee;
      border-radius: 4px;
    }
  }
}

/* Popover */
.popover.width-400 {
  max-width: 400px;
}

.popover.width-800 {
  max-width: none;
  width: 800px;

  &.bottom {
    margin-top: 5px;
  }

  .popover-content {
    padding: 0.7em 0;

    table.table {
      margin-bottom: 0;

      thead th {
        color: #707070;
      }
    }
  }
}

@media (max-width: 992px) {
  .popover.width-800 {
    max-width: 80%;
    width: 600px;
  }
}
@media (max-width: 768px) {
  .popover {
    display: none !important;
  }
}

/* Contract */
.warning-label {
  // display: inline;
  padding: 0.1em 0.6em;
  margin: 0 0.5em;
  // font-size: 75%;
  font-weight: bold;
  // line-height: 1;
  color: rgb(255, 136, 0);
  text-align: center;
  white-space: nowrap;
  // vertical-align: baseline;
  border-radius: 0.25em;

  background-color: rgba(255, 136, 0, 0.07);

  border-color: rgb(255, 136, 0);
  border-style: solid;
  border-width: 1px;
}

ol.contract {
  border: 1px solid #eee;
  border-radius: 0.35em;
  padding-top: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
  box-shadow: 0px 0.25em 1em 0px rgba(0, 0, 0, 0.05);

  negotiable-group > li {
    text-align: justify;
    padding: 0.3em 0.5em;
    border-style: dashed;
    border-width: 1px;
    border-radius: 0.35em;
    border-color: transparent;
    margin: 0.2em 0;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    &.outgoing {
      border-color: rgb(0, 131, 143);
      background-color: rgba(0, 131, 143, 0.05);
    }
    &.incoming {
      border-color: rgb(255, 136, 0);
      background-color: rgba(255, 136, 0, 0.05);
    }
    &.not-negotiable {
      b,
      span {
        color: #b71c1c !important;
      }
      b:after {
        opacity: 0.6;
        content: "[No negociable]";
        font-size: 75%;
        margin-left: 3px;
        color: #b71c1c;
      }
    }

    textarea {
      resize: none;
      margin-top: 0.2em;
    }
  }
}

/* Auction */
// La clase .order-view es un workaround para que no me pise el flex-direction que se utiliza en los filtros
@media (max-width: 768px) {
  .ag-container.order-view {
    flex-direction: column-reverse;
  }
}

.order-view {
  .auction {
    padding: 1em;

    .auction-container {
      padding: 1em;
      border: 1px dashed #ccc;
      border-radius: 8px;
      background-color: #f9f9f8;

      .auction-indicator {
        margin-bottom: 10px;
        padding: 8px 15px;
        // border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        color: #fff;
        box-shadow: inset 0px 2px 6px 0px rgba(0, 0, 0, 0.2);

        &.auction-indicator-orange {
          border-color: rgb(230, 81, 0);
          // color: #e65100;
          background-color: rgba(230, 81, 0, 0.9);
        }
        // &.auction-indicator-red {
        //   background-color: #c13331;
        // }
        &.auction-indicator-agree {
          border-color: rgb(60, 118, 61);
          // color: rgb(0, 209, 160);
          background-color: rgba(60, 118, 61, 0.9);
        }
      }

      hr {
        margin: 0.5em 0;
      }

      table.table.auction-info {
        width: auto;
        margin-bottom: 0;

        caption {
          color: inherit;
        }

        tr {
          // td:first-child {
          //   padding-left: 0;
          // }
          // td:last-child {
          //   padding-right: 0;
          // }
          td {
            padding: 0 2px;
            border-style: none;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .order-view {
    .auction {
      width: 300px;
    }
  }
}

/* Tabsets */
tabset {
  > .nav-tabs {
    > li {
      > a {
        border-top-width: 3px !important;
      }

      &.active > a {
        border-top-color: var(--primary) !important;
        border-top-style: solid !important;
        background-color: #fff;

        &:hover,
        &:focus {
          background-color: #fff;
        }
      }
    }
  }

  > .tab-content > .tab-pane {
    padding: 1em;
    border-style: none solid solid;
    border-color: #ddd;
    border-width: 1px;
    border-radius: 0 0 4px 4px;
  }
}

/* Navbar */
nav {
  height: 4.5rem;
}
.tablist {
  position: relative;
  padding: 0;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  min-width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  > ul.inner {
    display: table;
    margin: 0;
    padding: 0 27px;

    > li.tablist-tab {
      transition-property: background-color, color;
      transition-duration: 0.15s;
      display: table-cell;
      vertical-align: middle;
      margin: 0;
      padding: 0 30px;
      text-align: center;
      height: 4.5rem;
      white-space: nowrap;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 80%;
      letter-spacing: 0.3px;
      scroll-snap-align: none left;

      &.active {
        pointer-events: none;
        cursor: not-allowed;
      }
    }
  }
}

/* Column filter */
#filter-dropdown.dropdown-menu {
  margin-top: 6px;
  overflow: hidden;

  li {
    .dropdown-customitem {
      padding: 3px 10px;

      .form-group {
        margin-bottom: 0px;

        + .form-group {
          margin-top: 6px;
        }

        label {
          // width: 100%;
          // line-height: 2;
          // font-weight: normal;
          // text-transform: none;
          font-size: 10px;
        }
      }

      select,
      input {
        min-width: 200px;
      }
    }
  }
}

/* Editable Company pill */
.editable-pill {
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
  padding: 8px 16px;
}

/** Ribbon */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 110;
  pointer-events: none;
  top: -10px;

  &.ribbon-sm {
    width: 140px;
    height: 140px;

    &.ribbon-left > span {
      right: -14px;
    }
    &.ribbon-right > span {
      left: -14px;
    }

    > span {
      width: 198px;
      padding: 12px 0;
      font-size: 12px;
      line-height: 18px;
      top: 34px;
    }
  }
  &.ribbon-xs {
    width: 100px;
    height: 100px;

    &.ribbon-left > span {
      right: -10px;
    }
    &.ribbon-right > span {
      left: -10px;
    }

    > span {
      width: 141px;
      padding: 8px 0;
      font-size: 9px;
      line-height: 14px;
      top: 25px;
    }
  }

  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    content: "";
    display: block;
    border-style: solid;
    border-width: 5px;
    border-top-color: transparent;
  }

  &::after {
    bottom: 0;
  }

  &::before {
    top: 0;
  }
  > span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    top: 30px;
  }

  &.ribbon-left {
    left: -10px;

    &::before,
    &::after {
      border-left-color: transparent;
    }

    &::before {
      right: 0;
    }

    &::after {
      left: 0;
    }

    > span {
      right: -25px;
      transform: rotate(-45deg);
    }
  }

  &.ribbon-right {
    right: -10px;

    &::before,
    &::after {
      border-right-color: transparent;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    > span {
      left: -25px;
      transform: rotate(45deg);
    }
  }

  &.ribbon-default {
    &::before,
    &::after {
      border-color: #999;
    }

    > span {
      background-color: #aaa;
    }
  }
  &.ribbon-info {
    &::before,
    &::after {
      border-color: #2980b9;
    }

    > span {
      background-color: #3498db;
    }
  }
  &.ribbon-danger {
    &::before,
    &::after {
      border-color: #843533;
    }

    > span {
      background-color: $status-danger-color;
    }
  }
  &.ribbon-warning {
    &::before,
    &::after {
      border-color: #b37c0f;
    }

    > span {
      background-color: #e29d12;
    }
  }
  &.ribbon-agree {
    &::before,
    &::after {
      border-color: var(--primary-darken-1);
    }

    > span {
      background-color: var(--primary);
    }
  }
}
@media print {
  .ribbon {
    display: none;
  }
}

/** Extras */
.no-margin {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

td.td-empty > {
  .ag-label {
    // margin-top: 2px;
    margin-bottom: 3px;
  }
}
.ag-label {
  display: inline-block;
  text-shadow: 1px 1px 0px rgb(0 0 0 / 0.2);
  font-size: 75%;
  color: #fff;
  font-weight: bold;
  text-transform: none;
  padding: 0.2em 0.6em 0.3em;
  margin: 0;
  white-space: nowrap;
  border-radius: 0 4px 4px 0;
  line-height: 1;
  box-shadow: inset 0px 0px 1px 1px rgba(0, 0, 0, 0.2);

  + .ag-label {
    margin-left: 6px;
  }

  &.ag-label-lg {
    font-size: 100%;
  }
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 2147483647;
  box-sizing: border-box;

  &.inline {
    position: relative;
    z-index: 1000;

    > .ngx-toastr {
      margin: 0;
      width: auto;
      max-width: none;
      min-width: initial;
      border-radius: 0;
      box-shadow: none;
      height: auto;
      animation-name: expandDown;
      border-style: none none solid none;
      background-image: none !important;

      &:hover {
        box-shadow: none;
      }

      > .toast-message {
        padding: 12px 18px !important;
      }
    }
  }

  /* position */
  &.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.toast-top-center {
    top: 0;
    right: 0;
    width: 100%;
  }
  &.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
  }
  &.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
  }
  &.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
  }
  &.toast-top-left {
    top: 12px;
    left: 12px;
  }
  &.toast-top-right {
    top: 12px;
    right: 12px;
  }
  &.toast-bottom-right {
    right: 12px;
    bottom: 12px;
  }
  &.toast-bottom-left {
    bottom: 12px;
    left: 12px;
  }

  > .ngx-toastr {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    max-width: 300px;
    min-width: 160px;
    width: calc(100vw - 92px - 12px);

    border-radius: 4px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);

    pointer-events: auto;
    font-size: 12px;

    background-color: #f6f8f8;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: 16px 50%;
    border: 1px solid #c7d1d6;
    color: #455a64;

    animation-name: bounceInLeft;
    animation-duration: 0.6s;
    animation-fill-mode: both;

    &:hover {
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
      // opacity: 1;
      cursor: pointer;
    }

    &.toast-success {
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #bcdba3;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgba(188,219,163)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");

      > .toast-message {
        padding-left: 60px;
      }

      > .toast-title {
        border-color: #bcdba3;
      }

      .toast-progress {
        background-color: #bcdba3;
      }
    }
    &.toast-error {
      color: #a94442;
      background-color: #f2dede;
      border-color: #dda7b0;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(221,167,176)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");

      > .toast-message {
        padding-left: 60px;
      }

      > .toast-title {
        border-color: #dda7b0;
      }

      .toast-progress {
        background-color: #dda7b0;
      }
    }
    &.toast-info {
      color: #31708f;
      background-color: #d9edf7;
      border-color: #92cbe8;

      > .toast-title {
        border-color: #92cbe8;
      }

      .toast-progress {
        background-color: #92cbe8;
      }
    }
    &.toast-warning {
      color: #f56200;
      background-color: #fef3eb;
      border-color: #fbb7a2;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(251,183,162)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");

      > .toast-message {
        padding-left: 60px;
      }

      > .toast-title {
        border-color: #fbb7a2;
      }

      .toast-progress {
        background-color: #fbb7a2;
      }
    }

    /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
    // &.toast-info {
    //   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
    // }
    /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
    // &.toast-error {
    //   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
    // }
    /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
    // &.toast-success {
    //   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
    // }
    /* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
    // &.toast-warning {
    //   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
    // }

    > .toast-message {
      word-wrap: break-word;
      padding: 12px;
    }

    > .toast-title {
      font-weight: bold;
      font-size: 10px;
      padding: 6px 12px;
      border-bottom: 1px solid #c7d1d6;
      text-transform: uppercase;
    }

    > .toast-close-button {
      position: relative;
      top: 0;
      float: right;
      font-size: 17px;
      font-weight: bold;
      text-shadow: 0 1px 0 #ffffff;

      &:hover,
      &:focus {
        text-decoration: none;
        cursor: pointer;
        opacity: 0.4;
      }
    }

    /*Additional properties for button version
     iOS requires the button element instead of an anchor tag.
     If you want the anchor version, it requires `href="#"`.*/
    > button.toast-close-button {
      padding: 8px 12px;
      cursor: pointer;
      background: transparent;
      border: 0;
    }

    .toast-progress {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      background-color: #c7d1d6;
      // opacity: 0.3;
    }
  }
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  max-width: none;
  margin-left: auto;
  margin-right: auto;
}

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes expandDown {
  from {
    max-height: 0;
  }

  to {
    max-height: 600px; // still have to hard-code a value!
  }
}

/* HTML marker styles */
.gm-field-tag {
  background-color: #ea4336;
  border-radius: 20px;
  border: 2px solid #b31312;
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  // font-weight: bold;
  line-height: 1;
  min-height: 26px;
  min-width: 28px;
  padding: 4px;
  position: relative;
  text-align: center;

  &::after {
    border-color: #b31312 transparent transparent transparent;
    border-style: solid;
    border-width: 10px 8px 0 8px;
    content: " ";
    height: 0;
    width: 0;
    margin-left: -8px;
    position: absolute;
    left: 50%;
    top: 100%;
  }
}
